import axios, { endpoints } from '../../utils/axios'; // import dispatch from store

// ----------------------------------------------------------------------


export const createUser = async (userData) => {
  try {
    const response = await axios.post(endpoints.user, userData);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

// ----------------------------------------------------------------------
export const updateUser = async (userData) => {
  try {
    const response = await axios.put(endpoints.user, userData);
    return response.data.user;
  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

// ----------------------------------------------------------------------
export const getUser = async () => {
  try {
    const response = await axios.get(endpoints.user);
    return response.data.user;

  } catch (error) {
    throw new Error(error.response.data.message || error.message);
  }
}

// ----------------------------------------------------------------------

