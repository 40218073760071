import { Helmet } from 'react-helmet-async';

import { MotivationIllustration } from 'src/assets/illustrations';

import { GeneralPageView } from 'src/sections/general/index';

// ----------------------------------------------------------------------

export default function BigCommerceLaunchPage () {
  return (
    <>
      <Helmet>
        <title>BigCommerce Launch Page</title>
      </Helmet>

      <GeneralPageView
        header="Thank you!"
        subheader={
          <>
            Thanks for installing voolist.
            <br />
            Click the button below to import your listings from BigCommerce and start selling everywhere.
          </>
        }
        buttonLabel="Take me home"
        buttonHref="/listing"
        illustration={
          <MotivationIllustration
            sx={{
              height: 200,
              my: { xs: 5, sm: 10 },
            }}
          />
        }
      />
    </>
  );
}
