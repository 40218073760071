import { Navigate, useRoutes } from 'react-router-dom';

import NotFoundPage from "src/pages/404";
import ThankYouPage from 'src/pages/thank-you';
import {
  BigCommerceLaunchPage,
  BigCommerceInstallPage
} from 'src/pages/platforms';

// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------



export default function Router () {
  return useRoutes([
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    ...authRoutes,
    dashboardRoutes,
    ...mainRoutes,

    { path: '404', element: <NotFoundPage /> },
    {
      path: 'thank-you',
      element: <ThankYouPage />,
    },
    {
      path: 'bigcommerce-launch',
      element: <BigCommerceLaunchPage />,
    },
    {
      path: 'bigcommerce-install',
      element: <BigCommerceInstallPage />,
    },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
