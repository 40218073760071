import { Helmet } from 'react-helmet-async';

import { MotivationIllustration } from 'src/assets/illustrations';

import { GeneralPageView } from 'src/sections/general/index';

// ----------------------------------------------------------------------

export default function ThankYouPage () {
  return (
    <>
      <Helmet>
        <title> Thank you!</title>
      </Helmet>

      <GeneralPageView
        header="Congrats, You’re Connected! 🎉"
        subheader={
          <>
            Thanks for installing voolist.
            <br />
            Click the button below to check connected platforms and add more.
          </>
        }

        buttonLabel="Take me home"
        buttonHref="/stores"
        illustration={
          <MotivationIllustration
            sx={{
              height: 200,
              my: { xs: 5, sm: 10 },
            }}
          />
        }

      />
    </>
  );
}
