import { m } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useAuthContext } from 'src/auth/hooks';
import { getNotifications, readAllNotifications } from 'src/redux/slices/notification';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import EmptyContent from 'src/components/empty-content';
import { LoadingScreen } from 'src/components/loading-screen';

import NotificationItem from './notification-item';


// ----------------------------------------------------------------------

export default function NotificationsPopover () {
  const drawer = useBoolean();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { notifications } = useSelector((state) => state.notifications);

  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('unread');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const totalUnRead = notifications.filter((item) => !item.readStatus).length;
  const unreadNotifications = notifications.filter((item) => !item.readStatus);
  const readNotifications = notifications.filter((item) => item.readStatus);

  const handleMarkAllAsRead = () => {
    if (totalUnRead === 0) {
      return;
    }
    dispatch(readAllNotifications(user.id));
  };

  useEffect(() => {
    dispatch(getNotifications(user.id));
  }, [user.uid]); // eslint-disable-line

  const TABS = [
    {
      value: 'unread',
      label: 'Unread',
      count: totalUnRead,
    },
    {
      value: 'all',
      label: 'All',
      count: notifications.length,
    }
  ];

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderEmpty = (
    <EmptyContent
      sx={{
        mt: "20vh"
      }}
      title={
        currentTab === 'unread' ?
          'Hooraay, you are all caught up!' :
          "You don't have any notifications"
      }
      description={
        currentTab === 'unread' ?
          'You have no unread notifications' :
          'You have no notifications'
      }
    />
  )

  const renderList = (
    <Scrollbar>

      {
        (!notifications || notifications.length === 0) ? (
          <LoadingScreen />
        ) : (
          <List disablePadding>
            {
              (currentTab === 'unread' ? unreadNotifications : readNotifications).length === 0 ? (
                renderEmpty
              ) : (currentTab === 'unread' ? unreadNotifications : readNotifications)
                .map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))}
          </List>
        )
      }
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill"
              color={totalUnRead ? 'primary' : 'text.disabled'}
            />
          </IconButton>
        </Stack>

        <Divider />

        {
          renderList
        }

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View All
          </Button>
        </Box> */}
      </Drawer>
    </>
  );
}
