import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';

import CompactLayout from 'src/layouts/compact';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function GeneralPageView ({
  header,
  subheader,
  illustration,
  buttonHref,
  buttonLabel,

}) {
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {header}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            {subheader}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          {illustration}
        </m.div>

        <Button component={RouterLink} href={buttonHref} size="large" variant="contained" target='_blank'>
          {buttonLabel}
        </Button>
      </MotionContainer>
    </CompactLayout>
  );
}

GeneralPageView.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  illustration: PropTypes.node,
  buttonHref: PropTypes.string,
  buttonLabel: PropTypes.string,
};