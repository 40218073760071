import { Helmet } from 'react-helmet-async';

import { MotivationIllustration } from 'src/assets/illustrations';

import { GeneralPageView } from 'src/sections/general/index';

// ----------------------------------------------------------------------

export default function BigCommerceInstallPage () {
  return (
    <>
      <Helmet>
        <title>BigCommerce Install Page</title>
      </Helmet>

      <GeneralPageView
        header="Hey there! 👋"
        subheader={
          <>
            You are trying to install voolist for BigCommerce.
            <br />
            Click the button below to start the installation process from voolist.
            <br />
            Please ensure that the email address you use for BigCommerce matches the one you provided in Voolist during setup
          </>
        }
        buttonLabel="Install Voolist"
        buttonHref="/stores"
        illustration={
          <MotivationIllustration
            sx={{
              height: 200,
              my: { xs: 5, sm: 10 },
            }}
          />
        }
      />
    </>
  );
}
