import axios from 'axios';
import { getAuth } from 'firebase/auth';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  HOST_API,
  withCredentials: true
});


axiosInstance.interceptors.request.use(async (config) => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => Promise.reject(error));


export default axiosInstance;



// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/',
    login: 'api/user/login',
    register: 'api/user/register',
    ebay: `${HOST_API}/api/auth/ebay/getUrl`,
    etsy: `${HOST_API}/api/auth/etsy`,
    shopify: `${HOST_API}/api/auth/shopify`,
    wooCommerce: `${HOST_API}/api/auth/woocommerce`,
    bigCommerce: `${HOST_API}/api/auth/bigcommerce/install`,
    revoke: `${HOST_API}/api/auth/revoke`,
  },
  listing: {
    bulkCreate: `${HOST_API}/api/listings/bulk-create`,
    create: `${HOST_API}/api/listings`,
    list: `${HOST_API}/api/listings`,
    details: `${HOST_API}/api/listings/details`,
    update: `${HOST_API}/api/listings/update`,
    delete: `${HOST_API}/api/listings/delete`,
    import: `${HOST_API}/api/listings/import`,
    generate: `${HOST_API}/api/listings/generate`,
  },
  management: {
    ebay: {
      category: `${HOST_API}/api/management/category/ebay`,
      categoryFields: `${HOST_API}/api/management/category/ebay/categoryFields`,
      policies: `${HOST_API}/api/management/policies/ebay`
    },
    etsy: {
      category: `${HOST_API}/api/management/category/etsy`,
      categoryFields: `${HOST_API}/api/management/category/etsy/categoryFields`,
      shipping: `${HOST_API}/api/management/shipping/etsy`
    },
    marketplaces: `${HOST_API}/api/management/marketplaces`,
    settings: `${HOST_API}/api/management/settings`,
  },
  notification: `${HOST_API}/api/notifications`,
  user: `${HOST_API}/api/user`,

};
