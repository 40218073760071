import { createSlice } from '@reduxjs/toolkit';

// utils
import axios, { endpoints } from '../../utils/axios'; // import dispatch from store

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading (state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading (state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError (state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // RESET ERROR
    resetError (state, action) {
      state.isLoading = false;
      state.error = null;
    },

    // GET LISTINGS
    getNotificationsSuccess (state, action) {
      const notifications = action.payload;
      state.notifications = notifications;
      state.isLoading = false;
    },

  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getNotifications () {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(endpoints.notification);
      dispatch(slice.actions.getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.response.data.message || error.message));
      throw new Error(error.response.data.message || error.message);
    }
  };
}

// ----------------------------------------------------------------------
export function markNotificationAsRead (id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const url = `${endpoints.notification}/${id}`;
      const response = await axios.put(url);
      dispatch(slice.actions.getNotificationsSuccess(response.data));

    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(slice.actions.hasError(message));
    }
  };
}

// ----------------------------------------------------------------------
export function readAllNotifications () {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(endpoints.notification);
      dispatch(slice.actions.getNotificationsSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(slice.actions.hasError(message));
    }
  };
}

//----------------------------------------------------------------------
export function resetError () {
  return async (dispatch) => {
    dispatch(slice.actions.resetError());
  };
}

//----------------------------------------------------------------------

